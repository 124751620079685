import beforeunloadRequest from 'beforeunload-request';

export async function getCalculationById(id) {
    const { data } = await window.axios.get(`/insurance-products/calculations/${id}`,);
    return data;
}

export async function cascoLiteCalculation(form) {
    const {
        data
    } = await window.axios.post('/insurance-products/calculations/casco-lite', form);

    return data;
}
export async function cascoLiteSubscriptionCalculation(form) {
    const {
        data
    } = await window.axios.post('/insurance-products/calculations/subscription/casco-lite', form);

    return data;
}
export async function mostImportantCalculation(form) {
    const {
        data
    } = await window.axios.post('/insurance-products/calculations/most-important', form);
    return data;
}
export async function realEstateCalculation(form) {
    const {
        data
    } = await window.axios.post('/insurance-products/calculations/real-estate', form);
    return data;
}
export async function myHouseSubscriptionCalculation(form) {
    const {
        data
    } = await window.axios.post('/insurance-products/calculations/subscription/my-house', form);
    return data;
}
export async function flatSubscriptionCalculation(form) {
    const {
        data
    } = await window.axios.post('/insurance-products/calculations/subscription/apartment', form);
    return data;
}
export async function cheerUpCalculation(form) {
    const {
        data
    } = await window.axios.post('/insurance-products/calculations/cheer-up', form);
    return data;
}

export async function travelerCalculation(form) {
    const {
        data
    } = await window.axios.post('/insurance-products/calculations/travel', form);
    return data;
}
export async function travelerCovidCalculation(form) {
    const {
        data
    } = await window.axios.post('/insurance-products/calculations/covid-protect', form);
    return data;
}

export function savePolicyDraft(event, insurance_type, phone_number, payload) {
    window.axios.post('/insurance-products/calculations/cheer-up', {
        id: uuidv4(),
        insurance_type: insurance_type,
        phone_number: phone_number,
        payload: payload
    });
    const success = beforeunloadRequest(process.env.VUE_APP_BACKEND_URL + '/api/policy-draft', {
        method: "POST",
        body: JSON.stringify({
            id: uuidv4(),
            insurance_type: insurance_type,
            phone_number: phone_number,
            payload: payload
        }),
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Accept": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
    });

    if (!success) {
        event.preventDefault();
        event.returnValue = "";
    }
}

export function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}
