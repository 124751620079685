<template>
  <div>
    <section class="payment-success-header">
      <icon icon-name="success-icon" class="icon"></icon>
      <h3 class="title">{{$t('paymentSuccess')}}</h3>
      <p class="price">{{ price | priceFormat }} ₸</p>
      <p class="desc">
        {{$t('paymentReceipt')}}
      </p>
    </section>
    <div class="primary-block d-flex flex-column">
      <div class="d-flex align-items-center mb-2">
        <div class="icon-box me-3">
          <icon icon-name="halyk-icon"></icon>
        </div>
        <div class="text-block">
          <p class="primary-text">{{$t('insurancePolicy')}}</p>
          <p class="secondary-text">{{$t('halykIC')}}</p>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center py-2">
        <p>{{$t('selectedPayment')}} </p>
        <the-toggle-switch />
      </div>
    </div>
    <router-link
        :to="{ name: 'send.feedback', query: { productId: $route.params.id, invoiceId: $route.query.invoiceId } }"
      class="primary-btn bottom-page-btn"
      >{{$t('continueNext')}}</router-link
    >
  </div>
</template>

<script>
import TheToggleSwitch from "../../../components/TheToggleSwitch";
import {getCalculationById} from "../../../api/calculations";
import eventLogger from "../../../services/eventLogger";
export default {
  name: "PaymentSuccess",
  components: { TheToggleSwitch },
  data() {
    return {
      price: null
    }
  },
  async mounted() {
    await this.getPrice();
    await this.savePolicy();
  },
  methods: {
    async getPrice() {
      let data = null;

      try{
        const invoice = await window.axios.get(`get-invoice-by-ref/${this.$route.query.invoiceId}`);
        data = invoice.data
      } catch (e) {
        console.log(e)
      }

      if(!data) {
        await eventLogger('payment_success', {payment_sum: data?.amount})
      }

      const {calculationId} = data

      if(calculationId){
        let calculation =  await getCalculationById(calculationId);

        if(calculation) {
          let eventName = null;

          switch (calculation.insuranceType) {
            case 2220:
            case 2221:
              eventName = 'payment_success'
              break;
            case 3011:
            case 3017:
              eventName = 'payment_apartment_success';
              break;
            case 3012:
            case 3019:
              eventName = 'payment_house_success';
              break;
            case 6230:
            case 6231:
              eventName = 'payment_health_success';
              break;
            case 6610:
            case 6611:
              eventName = 'payment_finance_success';
              break;
            case 9100:
              eventName = 'payment_travel_success';
              break;
            case 3014:
              eventName = 'payment_apartment_subscription_success';
              break;
            case 3016:
              eventName = 'payment_house_subscription_success';
              break;
            case 2225:
              eventName = 'payment_kasko_subscription_success';
              break;
            default:
              eventName = null
              break;
          }
          await eventLogger(eventName, {
            payment_sum: data?.amount
          })
        }
      }
      this.price = data?.amount;
    },
    async savePolicy() {
      let loader = this.$loading.show({});

      await window.axios.post('insurance-products/save-policy', {
        invoice_id: this.$route.query.invoiceId,
        calculation: this.$route.params.id
      })

      loader.hide()
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/variables";
.payment-success-header {
  width: 100%;
  background-color: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 26px 16px 50px;
  margin-bottom: 16px;
  .icon {
    margin-bottom: 24px;
  }
  .title {
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 8px;
  }
  .price {
    color: #fff;
    font-weight: 800;
    font-size: 40px;
    margin-bottom: 8px;
  }
  .desc {
    color: #ffffff;
  }
}
.primary-block {
  .text-block {
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid #c8c7cc;
  }
}
</style>
